import Navbar from "../../components/Navbar";
import NavBarMobile from "../../components/NavBarMobile";
import CmsPage from "../../components/CmsPage";

import { loadPage } from '../../lib/api-lib'

const paths = [];

function Page({ structure }) {
  return (
    <>
      {typeof structure !== 'undefined' ? 
      <>
        <Navbar navigation={structure.navigation ?? ''} />
        <NavBarMobile navigation={structure.navigation ?? ''} />
        <CmsPage structure={structure} />
      </>
      : ''}
    </>
  )
}

export async function getStaticProps(context) {
  // console.log(context)
  const data = await loadPage(context.params.slug.join('/')) || false;
  if(data == false) {
    return {
      notFound: true
    };
  } else {
    return {
      props: {
        structure: data,
      },
      revalidate: 10,
    };
  }
}

export async function getStaticPaths() {
  const json = await loadPage() // homepage

  json.navigation.map(function(link) {
    recursiveStaticPathGeneration(link);
  })

  paths.push('/page/privacy')

  console.log(paths);

  return {
    paths: paths,
    fallback: true,
  }
}

function recursiveStaticPathGeneration(link) {
  var slugVal;    
  if(typeof link.path != 'undefined') {
    slugVal = link.path
    if(slugVal != '') {
      slugVal = slugVal.split('/')
      slugVal.shift()
      // console.log(slugVal)
      // paths.push({ params: { 'slug': slugVal }})
      paths.push('/page/'+slugVal)
    }
  }
  if(link.navigation.length > 0) {
    link.navigation.map(function(slink) {
      recursiveStaticPathGeneration(slink)
    })
  }
}

export default Page